import { useState } from "react";
import { FormConig , PageData } from "../interfaces/index";
import { ConsultationPage, ConsultationFields } from '../constants/index'
import {Header} from "../components/header/header";
import { Form} from "../components";

export const ConsultationForm = () => {
  const [inputAttributes, setFormConig] = useState<FormConig[]>(
    ConsultationFields
  );
  const [pageData, setPageData] = useState<PageData>(ConsultationPage);

  return (
    <div>
      <Header img={pageData.image} headerLabel={pageData.title} />
      <Form formHeaderText={pageData.header} inputAttributes={inputAttributes} />
    </div>
  );
}

