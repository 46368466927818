import "./formStyle.scss";
import emailjs from "emailjs-com";
import { FormConig } from "../../interfaces";
import { FormEvent, Key, useEffect, useRef, useState } from "react";
import { Captcha, FileInput, Condition, Input } from "../index";
import { TextArea } from '../textArea/textarea';

type FormProps = {
  formHeaderText: string;
  inputAttributes: FormConig[];
};

export const Form = ({ formHeaderText, inputAttributes }: FormProps) => {
  const [confirmConditions, setConfirmConditions] = useState(false);
  const [confirmCaptcha, setConfirmCaptcha] = useState(false);
  const [isValidateInput, setIsValidateInput] = useState(false);

  const theForm = useRef<HTMLFormElement>(null);

  useEffect(() => {
    console.log(isValidateInput);
  }, [isValidateInput]);

  const checkValidation = (type: string , value: string) =>{
    if(type === 'email'){
      var re = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
      if(re.test(value)){
        return true
      }
    }
    if(type === 'phone'){
      var re =/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
      if(re.test(value)){
       return true
      } 
    }     
    return false
}


  const sendEmail = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let count = 0
    for(const att of inputAttributes) {      
      if (theForm.current) {
        let response = checkValidation(theForm.current[att.name].name, theForm.current[att.name].value)
        console.log(`type: ${theForm.current[att.name].name} , value: ${theForm.current[att.name].value}`);
        console.log(response)
        if(response){
          count++
        }
      }
    }
    
    if(count === inputAttributes.length){
      alert('valid')
    }
    if (confirmConditions && confirmCaptcha && isValidateInput) {
      emailjs
        .sendForm(
          "gmail",
          "template_7rl275k",
          theForm.current!,
          "1ew4c0NxEiHVV2o12"
        )
        .then(
          (result: { text: any }) => {
            console.log(result.text);
          },
          (error: { text: any }) => {
            console.log(error.text);
          }
        );
    } else {
      console.log("error");
    }
  };

  return (
    <div className="form-container">
      <div className="form-div">
        <div className="slices">
          <ul className="slices-line"></ul>
        </div>
        <p className="form-header">{formHeaderText}</p>

        <form onSubmit={(e) => sendEmail(e)} ref={theForm}> 
          {inputAttributes.map(
            (attribute: FormConig, index: Key | null | undefined) => {
              return (
                <div key={index}>
                  {attribute.domType === "input" && (
                    <Input inputAttribute={attribute}/>
                  )}

                  {attribute.domType === "textarea" && (
                    <TextArea inputAttribute={attribute} />
                  )}

                  {attribute.domType === "file" && (
                    <FileInput inputAttribute={attribute} />
                  )}
                </div>
              );
            }
          )}
          <div className="conditions">
            <Condition setConfirmConditions={setConfirmConditions} />
            <Captcha setConfirmCaptcha={setConfirmCaptcha} />
          </div>
          <div>
            <button className="submit-button" type="submit">
              שליחת פרטים
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
