import "../header/header.scss";
import tevaCannLogo from "../../assets/images/tevacannlogo.png";
import tevaLogo from "../../assets/images/logo_teva.svg";

interface Props {
  img: string;
  headerLabel: string;
}

export const Header = ({ img, headerLabel }: Props) =>{
  return (
    <>
      <div className="green-line"></div>
      <div className="navbar space-between">
        <img alt="teva-cann-logo" className="teva-cann-logo" src={tevaCannLogo} />
        <img alt="teva-logo" className="teva-logo" src={tevaLogo} />
      </div>
      <div className="header flex center">
        <img alt="header" className="header-image" src={img} />
        <div className="header-label">
          <h1>{headerLabel}</h1>
        </div>
      </div>
    </>
  );
}

