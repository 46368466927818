import { FormConig, PageData } from "../interfaces/index";
import doctor from "../assets/images/doctor.svg";

export const SendPrescriptionInputsFields: FormConig[] = [
  {
    name: "firstname",
    label: "שם פרטי",
    type: "text",
    domType: "input",
    errorMsg: 'Invalid First Name',
  },
  {
    name: "lastname",
    label: "שם משפחה",
    type: "text",
    domType: "input",
    errorMsg: 'Invalid Last Name'
  },
  {
    name: "phone",
    label: "טלפון אישי",
    type: "tel",
    domType: "input",
    errorMsg: 'Invalid Phone number'
  },
  {
    name: "email",
    label: "כתובת אימייל",
    type: "email",
    domType: "input",
    errorMsg: 'Invalid Email'
  },
  {
    name: "address",
    label: "כתובת משלוח",
    type: "text",
    domType: "input",
    errorMsg: 'Invalid Address'
  },
  {
    name: "id",
    label: "מספר תעודת זהות",
    type: "number",
    domType: "input",
    errorMsg: 'Invalid Id'
  },
  {
    name: "licenseFile",
    label: "קובץ רשיון קנאביס רפואי בתוקף",
    type: "file",
  
    domType: "file",
  },
  {
    name: "prescriptionFile",
    label: "קובץ מרשם לקנאביס רפואי בתוקף",
    type: "file",
    domType: "file",
  },
  {
    name: "idFile",
    label: "צילום ת״ז",
    type: "file",
    domType: "file",
  },
];

export const SendPrescriptionPage: PageData = {
  title: "בקשה לניפוק קנביס רפואי",
  image: doctor,
  header:
    "מטופלים יקרים, חסכו המתנה בתור, אנו נחזור אליכם לאחר קליטת המסמכים ובדיקתם לשם אימות רוקחי וביצוע ההזמנה. אנא הזינו את הפרטים ואת המסמכים הדרושים.",
};
