import { Links, SharedButtons } from "../interfaces/index";
const url = "https://productbrand.tevapharm.com";

export const FooterLinksPart1: Links[] = [
  {
    href: "https://www.teva.co.il/globalassets/teva-il-scs-vision/teva-il-scs-vision-files/about-teva/privacy_and_cookies_notice_hebrew.pdf",
    target: "_blank",
    text: "  מדיניות פרטיות",
  },
  { href: url + "/he-il/tevacann/", target: "_self", text: "תנאי שימוש" },
  {
    href: "https://www.teva.co.il/about-teva/accessibility-statement/",
    target: "_blank",
    text: "הצהרת נגישות",
  },
  { href: "https://www.teva.co.il/", target: "_blank", text: " אתר טבע ישראל" },
];

export const FooterLinksPart2: Links[] = [
  { href: url + "/he-il/tevacann/about-us/", target: "_self", text: "אודות" },
  { href: url + "/he-il/tevacann/products/", target: "_self", text: "מוצרים" },
  { href: "https://www.teva.co.il/", target: "_self", text: "כתבות" },
  {
    href: url + "/he-il/tevacann/training-videos/",
    target: "_self",
    text: "סרטוני הדרכה",
  },
];

export const FooterLinksPart3: Links[] = [
  {
    href: url + "/he-il/tevacann/faqs/",
    target: "_self",
    text: "שאלות ותשובות",
  },
  {
    href: url + "/he-il/tevacann/nurse-counseling/",
    target: "_self",
    text: "ליווי אחיות",
  },
  {
    href: url + "/he-il/tevacann/contact-us/",
    target: "_self",
    text: "צור קשר",
  },
];

export const FollowUs: SharedButtons[] = [
  {
    href: "https://www.facebook.com/tevapharm",
    title: "facebook",
    path: "M30,15 C30,6.71572266 23.2842773,0 15,0 C6.71572266,0 0,6.71572266 0,15 C0,22.4869336 5.4852832,28.6924805 12.65625,29.8177148 L12.65625,19.3359375 L8.84765625,19.3359375 L8.84765625,15 L12.65625,15 L12.65625,11.6953125 C12.65625,7.9359375 14.8956738,5.859375 18.3219434,5.859375 C19.963125,5.859375 21.6796875,6.15234375 21.6796875,6.15234375 L21.6796875,9.84375 L19.7881934,9.84375 C17.9248535,9.84375 17.34375,11 17.34375,12.1862402 L17.34375,15 L21.5039062,15 L20.8388672,19.3359375 L17.34375,19.3359375 L17.34375,29.8177148 C24.5147168,28.6924805 30,22.4869336 30,15",
  },
  {
    href: "https://twitter.com/tevapharmeurope",
    title: "twitter",
    path: "M29.7244391,10.4727174 C29.7436804,10.7543155 29.7436804,11.0372137 29.7436804,11.321282 C29.7436804,19.9971305 23.1388652,30.0016646 11.0632978,30.0016646 L11.0632978,29.9964642 C7.49586817,30.0012746 4.00241328,28.9792788 1,27.0525547 C1.51860339,27.1145687 2.04045698,27.1462908 2.56283061,27.1472008 C5.51974096,27.150191 8.39162584,26.1583572 10.7164355,24.3312195 C7.90760468,24.2784361 5.442776,22.4463582 4.58277064,19.771826 C5.56654397,19.9613782 6.58060925,19.9228958 7.54722148,19.6592388 C4.48383427,19.0403989 2.28084245,16.3487057 2.2800624,13.2234345 L2.2800624,13.1402291 C3.19298117,13.6492119 4.21497696,13.93107 5.25985423,13.962012 C2.3747085,12.035418 1.4846712,8.19770095 3.22717337,5.19788783 C6.56097799,9.29939187 11.4791946,11.7926924 16.7580544,12.0573894 C16.2287904,9.77730263 16.9522869,7.38787881 18.6573467,5.78435559 C21.3011969,3.29807553 25.4601646,3.42561374 27.9465747,6.06946394 C29.4168393,5.77967529 30.8267801,5.24066059 32.1154231,4.47595136 C31.6254215,5.99626923 30.5996555,7.2867323 29.2291072,8.10721512 C30.530621,7.95341522 31.8017129,7.60525281 33,7.07429863 C32.1185433,8.39258349 31.0093118,9.54328757 29.7244391,10.4727174",
  },
  {
    href: "https://www.linkedin.com/company/teva-pharmaceuticals",
    title: "linkedin",
    path: "M26.8823529,18.8776471 C26.8823529,14.9164706 24.3623529,13.3764706 21.8588235,13.3764706 C20.1903529,13.2932941 18.6042353,14.102 17.6917647,15.5011765 L17.5764706,15.5011765 L17.5764706,13.7058824 L13.7058824,13.7058824 L13.7058824,26.8823529 L17.8235294,26.8823529 L17.8235294,19.8741176 C17.7,18.3802353 18.8018824,17.0650588 20.2941176,16.9258824 L20.4505882,16.9258824 C21.76,16.9258824 22.7317647,17.7494118 22.7317647,19.8247059 L22.7317647,26.8823529 L26.8494118,26.8823529 L26.8823529,18.8776471 Z M11.6058824,9.17647059 C11.6149412,7.86623529 10.5608235,6.79729412 9.25058824,6.78823529 L9.25058824,6.78823529 L9.17647059,6.78823529 C7.85717647,6.78905882 6.78905882,7.85882353 6.78905882,9.17729412 C6.78988235,10.4957647 7.858,11.5638824 9.17647059,11.5647059 C10.4858824,11.5968235 11.5737647,10.5616471 11.6058824,9.25223529 C11.6058824,9.25141176 11.6058824,9.25141176 11.6058824,9.25058824 L11.6058824,9.17647059 Z M7.11764706,26.8823529 L11.2352941,26.8823529 L11.2352941,13.7058824 L7.11764706,13.7058824 L7.11764706,26.8823529 Z M31,5.05882353 L31,28.9411765 C31,30.0784706 30.0784706,31 28.9411765,31 L5.05882353,31 C3.92152941,31 3,30.0784706 3,28.9411765 L3,5.05882353 C3,3.92152941 3.92152941,3 5.05882353,3 L28.9411765,3 C30.0784706,3 31,3.92152941 31,5.05882353 L31,5.05882353 Z",
  },
];

export const allLinks = [FooterLinksPart1, FooterLinksPart2, FooterLinksPart3];
