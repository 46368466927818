import ReCAPTCHA from "react-google-recaptcha";
import "./captchaStyle.scss";
import { Dispatch, SetStateAction } from "react";

type Props = {
  setConfirmCaptcha: Dispatch<SetStateAction<boolean>>;
};

export const Captcha = ({ setConfirmCaptcha }: Props) => {
  const handelConfirmCaptcha = (e: string | null) => {
    if (e) setConfirmCaptcha(true);
  };

  const recaptchaExpired = () => {
    setConfirmCaptcha(false);
  };

  return (
    <div className="captcha">
      <ReCAPTCHA
        sitekey="6Lc0alEhAAAAAAooiwnBK_KLoMnfIU7dS_wWCwbO"
        onChange={(e) => handelConfirmCaptcha(e)}
        size="compact"
        onExpired={recaptchaExpired}
      />
    </div>
  );
};
