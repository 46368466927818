import {useState } from "react";
import "./inputStyle.scss";
import { FormConig } from "../../interfaces";

interface Props {
  inputAttribute: FormConig;
}

export const Input = ({ inputAttribute, }: Props) => {
  const [validateInput, setValidateInput] = useState(false)

  
  const checkValidation = (e : any) =>{
    if(inputAttribute.name === 'id'){
      if(e.length !=9){
       return true
      } 
    }   
      if(inputAttribute.type === 'email'){
        var re = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
        if(!re.test(e)){
          return true
        }
      }
      if(inputAttribute.type === 'tel'){
        var re =/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
        if(!re.test(e)){
         return true
        } 
      }     
      if(inputAttribute.type === 'text'){
        if(e.length <2){
         return true
        } 
      }   
      return false
  }

  const handleChange = (e:string | number) =>{
    const response= checkValidation(e)
    setValidateInput(response)
  }

  return (
    <div className="form-element">
      <label htmlFor={inputAttribute.name}>
        {" "}
        {inputAttribute.label}
        <span className="must-text"> חובה</span>
      </label>

      <input
        className="form-input"
        name={inputAttribute.name}
        type={inputAttribute.type}
        onChange={e=> handleChange(e.target.value)}
        required
      />
      {validateInput && 
            <label htmlFor={inputAttribute.name}>
            <span className="invalid-feedback"> {inputAttribute.errorMsg}</span>
          </label>
      }
    </div>
  );
};


