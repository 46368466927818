import React from 'react';
import {Footer} from './components/footer/footer';
import { ConsultationForm } from './formPages/consultationForm';
import { ContactUsForm } from './formPages/contactUsForm';
import {SendPrescriptionForm} from './formPages/SendPrescriptionForm';
import { Routes, Route } from 'react-router-dom';

export const App= () => {
  return (
    <div className="App">
      <Routes>
       <Route path='/prescription' element={<SendPrescriptionForm/>} />
       <Route path='/contact-us' element={<ContactUsForm/>} />
       <Route path='/consultation' element={<ConsultationForm/>} />
      </Routes>
      <Footer/>
    </div>
  );
}

