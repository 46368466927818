import { useState } from "react";
import { FormConig } from "../../interfaces";
import "../fileInput/fileInputStyle.scss";

interface Props {
  inputAttribute: FormConig;
}

export const FileInput = ({ inputAttribute }: Props) => {
  const [file, setfile] = useState("");
  const [validateInput, setValidateInput] = useState(false);
  const fileTypes= ['image/png', 'image/jpeg', 'image/svg+xml', 'application/pdf']

  const handleselectedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.files![0].type);
    if(fileTypes.includes(event.target.files![0].type)){
      setfile(event.target.files![0].name);
      setValidateInput(false)
    }
    else{
      setValidateInput(true)
    }
  };

  return (
    <div className="file-input">
      <>
        <label className="input-label" htmlFor={inputAttribute.name}>
          {inputAttribute.label}
          <span className="must-text"> חובה</span>
        </label>
      </>

      <div className="custom-file">
        <input
          type="file"
          className="custom-file-input"
          name={inputAttribute.name}
          data-attachment-id="86906"
          data-email-binding={inputAttribute.name}
          required
          accept="image/*,.pdf"
          data-msg=""
          data-msg-success=""
          onChange={handleselectedFile}
        />
        <label className="custom-file-label" htmlFor={inputAttribute.name}>
          {file === "" ? "גרור קובץ או עיין מתוך המחשב שלך" : file}
        </label>
      </div>
      {validateInput ? (
        <label htmlFor={inputAttribute.name}>
          <span className="invalid-feedback"> Invalid File</span>
        </label>
      ) : (
        file !== "" && <div className="file-name">{file}</div>
      )}
    </div>
  );
};
