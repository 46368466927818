import { FormConig, PageData } from "../interfaces/index";
import contactUs from "../assets/images/contactUs.svg";

export const ContactUsFields: FormConig[] = [
  {
    name: "firstname",
    label: "שם פרטי",
    type: "text",
    domType: "input",
    errorMsg: 'Invalid First Name',
  },
  {
    name: "lastname",
    label: "שם משפחה",
    type: "text",
    domType: "input",
    errorMsg: 'Invalid Last Name'

  },
  {
    name: "phone",
    label: "טלפון אישי",
    type: "tel",
    domType: "input",
    errorMsg: 'Invalid Phone number'
  },
  {
    name: "email",
    label: "כתובת אימייל",
    type: "email",
    domType: "input",
    errorMsg: 'Invalid Email'
  },
  {
    name: "descriprion",
    label: "תיאור הפנייה",
    type: "textarea" ,
    domType: "textarea",
    errorMsg: 'Invalid Description'
  }
];

export const ContactUsPage: PageData = {
  title: "TevaCann כאן בשבילכם",
  image: contactUs,
  header:
    "מטופלים יקרים, מלאו את הטופס ונציג מטעמנו יחזור אליכם.",
};
