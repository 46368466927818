import { FormConig, PageData } from "../interfaces/index";
import consultation from "../assets/images/consultation.svg";

export const ConsultationFields: FormConig[] = [
  {
    name: "firstname",
    label: "שם פרטי",
    type: "text",
    domType: "input",
    errorMsg: 'Invalid First Name',
  },
  {
    name: "lastname",
    label: "שם משפחה",
    type: "text",
    domType: "input",
    errorMsg: 'Invalid Last Name'

  },
  {
    name: "phone",
    label: "טלפון אישי",
    type: "tel",
    domType: "input",
    errorMsg: 'Invalid Phone number'
  },
  {
    name: "email",
    label: "כתובת אימייל",
    type: "email",
    domType: "input",
    errorMsg: 'Invalid Email'
  },
];

export const ConsultationPage: PageData = {
  title: "ייעוץ עם אחות מוסמכת",
  image: consultation,
  header:
    "נא מלא/י את הטופס ואחות מוסמכת תחזור אליך.",
};
