import "./textAreaStyle.scss";
import { FormConig } from "../../interfaces";

interface Props {
  inputAttribute: FormConig;
}

export const TextArea = ({ inputAttribute }:Props) => {
  return (
    <div className="form-element">
      <label htmlFor={inputAttribute.name}>
        {" "}
        {inputAttribute.label}
        <span className="must-text"> חובה</span>
      </label>

      <textarea
        className="form-input"
        name={inputAttribute.name}
        cols={30}
        rows={6}
        required
      />
    </div>
  );
};
